// @ts-strict-ignore
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import FileSystemBackend from 'i18next-fs-backend';
import { SUPPORTED_LOCALES } from '@/main/app.constants';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    compatibilityJSON: 'v3',
    supportedLngs: SUPPORTED_LOCALES,
    nonExplicitSupportedLngs: false,
    interpolation: {
      skipOnVariables: true,
      escapeValue: false,
    },
    backend: {
      backends: [FileSystemBackend, HttpApi],
      backendOptions: [
        {
          loadPath: '/resources/{{lng}}.json',
        },
        {
          loadPath: '/resources/{{lng}}.json',
        },
      ],
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
