// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { lastWorkstepAction, nextWorkstepAction, previousWorkstepAction } from '@/worksteps/worksteps.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { Icon } from '@/core/Icon.atom';
import classNames from 'classnames';
import { generate, getViewFromWorkstep } from '@/utilities/screenshot.utilities';
import { sqStateSynchronizer, sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { fetchAnnotations } from '@/annotation/annotation.actions';

export type WorkstepButtonType = 'previous' | 'next' | 'last';

interface WorkstepIconsProps {
  isViewMode: boolean;
  isEditMode: boolean;
}

export const WorkstepIcons: React.FunctionComponent<WorkstepIconsProps> = ({ isViewMode, isEditMode }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { previous, next, last, current, isError } = useFlux(sqWorkstepsStore);
  const previousAvailable = !_.isEmpty(previous);
  const nextAvailable = !_.isEmpty(next);
  const lastAvailable = !_.isEmpty(last) && last !== current.id;

  /**
   * Rehydrates a workstep, unless it is already in the middle of rehydrating.
   *
   * @param {Function} workstepAction - The action to take that gets the workstep to rehydrate
   * @return {Promise<void>} The rehydrate promise
   */
  const rehydrateWorkstep = (workstepAction) => {
    if (!isProcessing) {
      setIsProcessing(true);
      return workstepAction()
        .then((response) => {
          const workbookId = sqWorkbenchStore.stateParams.workbookId;
          const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
          sqStateSynchronizer.setLoadingWorksheet(workbookId, worksheetId);
          sqStateSynchronizer.rehydrate(response.current.state, { workbookId, worksheetId }).finally(() => {
            sqStateSynchronizer.unsetLoadingWorksheet();
          });
          return response;
        })
        .then((response) => {
          const viewKey = getViewFromWorkstep(response);
          generate({
            workbookId: sqWorkbenchStore.stateParams.workbookId,
            worksheetId: sqWorkbenchStore.stateParams.worksheetId,
            workstepId: sqWorkstepsStore.current.id,
            defer: true,
            viewKey,
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      return Promise.resolve();
    }
  };

  /**
   * Navigates to the specified workstep if it's available.
   *
   * @param {String} which - The identifier (previous, next, last) of the workstep to navigate to
   * @param {Boolean} isAvailable - A flag identifying if the desired workstep is available
   * @returns {Promise} A promise that resolves when the workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  const goToWorkstep = (which: WorkstepButtonType, isAvailable) => {
    if (isAvailable) {
      return rehydrateWorkstep(() => {
        switch (which) {
          case 'previous':
            return previousWorkstepAction(
              sqWorkbenchStore.stateParams.workbookId,
              sqWorkbenchStore.stateParams.worksheetId,
            );
          case 'next':
            return nextWorkstepAction(
              sqWorkbenchStore.stateParams.workbookId,
              sqWorkbenchStore.stateParams.worksheetId,
            );
          case 'last':
            return lastWorkstepAction(
              sqWorkbenchStore.stateParams.workbookId,
              sqWorkbenchStore.stateParams.worksheetId,
            );
        }
      }).then(fetchAnnotations);
    }
  };

  return (
    <div
      className={isViewMode ? 'flexSelfStretch' : 'flexColumnContainer flexJustifyEnd ml10 mr1 flexNoGrowNoShrink'}
      data-testid="workstepIcons">
      {isEditMode && !sqWorkbookStore.isReportBinder && (
        <div className="unselectable">
          {isError && (
            <Icon
              icon="fa-exclamation-triangle"
              extraClassNames="workstepButton fa-xlg pr10"
              type="danger"
              tooltip="WORKSTEPS.ERROR"
              tooltipPlacement="bottom"
            />
          )}
          <Icon
            icon="fa-reply"
            extraClassNames={classNames({ workstepButton: previousAvailable }, 'fa-xlg pr12')}
            type={previousAvailable ? 'theme-light' : 'gray'}
            onClick={
              previousAvailable
                ? () => {
                    goToWorkstep('previous', previousAvailable);
                  }
                : null
            }
            testId="previousWorkstep"
            tooltip="WORKSTEPS.PREVIOUS"
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-share"
            extraClassNames={classNames({ workstepButton: nextAvailable }, 'fa-xlg pr10')}
            type={nextAvailable ? 'theme-light' : 'gray'}
            onClick={
              nextAvailable
                ? () => {
                    goToWorkstep('next', nextAvailable);
                  }
                : null
            }
            testId="nextWorkstep"
            tooltip="WORKSTEPS.NEXT"
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-reply-all"
            extraClassNames={classNames({ workstepButton: lastAvailable }, 'fa-xlg pr3 pl9 fa-flip-horizontal')}
            type={lastAvailable ? 'theme-light' : 'gray'}
            onClick={
              lastAvailable
                ? () => {
                    goToWorkstep('last', lastAvailable);
                  }
                : null
            }
            testId="lastWorkstep"
            tooltip="WORKSTEPS.LAST"
            tooltipPlacement="bottom"
          />
        </div>
      )}
    </div>
  );
};
